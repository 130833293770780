import { Component, Input } from '@angular/core'
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthConfig } from 'angular-oauth2-oidc';
import { GoogleAuthService } from '@app/shared/services/google-auth.service'
import { MsalService } from '@azure/msal-angular'
import { UserService } from '@app/shared/services/user.service'

declare namespace google {
  namespace accounts {
    namespace oauth2 {
      interface TokenClientConfig {
        client_id: string;
        scope: string;
        callback: (response: any) => void;
      }

      interface TokenClient {
        requestAccessToken(): void;
      }

      function initTokenClient(config: TokenClientConfig): TokenClient;
      function revoke(config: { token: string, hint: string }): Promise<void>;
    }
  }
}

export const googleOAuthCodeFlowConfig: AuthConfig = {
  issuer: 'https://accounts.google.com',
  redirectUri: window.location.origin + '/profile/login',
  clientId: '879107131855-mvimcssuvhdv34docstj851jbdq7ktnv.apps.googleusercontent.com',
  responseType: 'code',
  dummyClientSecret: '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',
  scope: 'openid profile email https://www.googleapis.com/auth/calendar https://mail.google.com https://www.googleapis.com/auth/tasks',
  oidc: true,
  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false,
  requestAccessToken: true,
  customQueryParams: {
    'access_type': 'offline'
  },
};

@Component({
  selector: 'app-login-socmed',
  templateUrl: './login-socmed.component.html',
  styleUrls: [ './login-socmed.component.scss' ]
})
export class LoginSocmedComponent {
  public microsoftIdentity: any = {}

  @Input() haveTokens: { [key: string]: boolean } = {};
  constructor (
    private oauthService: OAuthService,
    private googleAuthService: GoogleAuthService,
    private msalService: MsalService,
    private userService: UserService,
  ) {
    this.userService.getCloudTokens().subscribe(result => {
      this.haveTokens = result;
    });

    this.oauthService.configure(googleOAuthCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
  socmeds = [
    {
      key: 'google',
      name: 'Google',
      icons: {
        disconnected: './assets/svg/socmed/google.svg',
        connected: './assets/svg/socmed/googleColor.svg',
      },
    },
    {
      key: 'microsoft',
      name: 'Microsoft',
      icons: {
        disconnected: './assets/svg/socmed/microsoft.svg',
        connected: './assets/svg/socmed/microsoftColor.svg',
      },
    },
  ];

  isConnected(key: string): boolean {
    if (this.haveTokens && this.haveTokens[key]) {
      return this.haveTokens[key]
    }
    return false
  }

  connectAccount(platform: string) {
    if (this.isConnected(platform)) {
      // If already connected, then disconnect
      this.disconnectAccount(platform);
    } else {
      // Current connection logic
      switch (platform) {
        case 'google':
          this.connectGoogle();
          break;
        case 'microsoft':
          this.connectMicrosoft();
          break;
        default:
          console.warn(`No OAuth process defined for ${platform}`);
      }
    }
  }

  connectGoogle() {
    google.accounts.oauth2.initTokenClient({
      client_id: '879107131855-mvimcssuvhdv34docstj851jbdq7ktnv.apps.googleusercontent.com',
      scope: 'openid profile email https://www.googleapis.com/auth/calendar https://mail.google.com https://www.googleapis.com/auth/tasks',
      callback: (response) => {
        this.userService.saveCloudToken('google', response).subscribe((saveResult) => {
          this.haveTokens['google'] = true;
        });
      },
    }).requestAccessToken();
  }

  async connectMicrosoft () {
    this.msalService.initialize().subscribe(() => {
        this.msalService.loginPopup().subscribe((result) => {
          console.log('loginPopup', result);
            this.microsoftIdentity = result;
            this.userService.saveCloudToken('microsoft', this.microsoftIdentity).subscribe((saveResult) => {
              this.haveTokens['microsoft'] = true
            })
        });
    });
  }

  disconnectAccount(platform: string) {
    const isConfirmed = window.confirm('Are you sure you want to disconnect this account? You will lose your connection to this account source and will need to reconnect to use it again.');
    if (!isConfirmed) {
      return;
    }
    switch (platform) {
      case 'google':
        console.log('disconnecting google...');
        this.oauthService.revokeTokenAndLogout().then(() => {
          this.googleAuthService.revokeGoogleToken().subscribe(() => {
            this.haveTokens['google'] = false;
          })
        }
        ).catch(e => console.error('error', e));
        break;
      case 'microsoft':
        console.log('disconnecting microsoft...');
        // Call your API or service method to disconnect from Microsoft
        break;
      default:
        console.warn(`No disconnect process defined for ${platform}`);
    }
    // Update the haveTokens state to reflect disconnection
    this.haveTokens[platform] = false;
  }
}
